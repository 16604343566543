
/**
 * @name: 商品管理-第三方商品库
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商品管理-第三方商品库
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  productPageApi,
  productDetailApi,
  productAddShelvesApi,
  productPullApi,
  productExportApi,
  productModifyApi,
  productSkuDetailApi,
  productNewPageApi
} from '@/apis/product/third-product'
import {
  sortPageApi
} from '@/apis/product/sort'
import { checkPrice } from '@/constants/validators'
import type { ICrudOption } from "@/types/m-ui-crud"
import type { IProductSort } from "@/apis/product/sort/types";
import { IProduct, IProductParams, IProductSku } from "@/apis/product/third-product/types";
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class productThirdProduct extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IProduct[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IProductParams = {
    page: 1,
    limit: 10,
    productType: 2
  }
  // 表单参数
  modelForm: Partial<IProduct> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    editTitle: '编辑商品',
    viewTitle: '商品详情',
    column: [
      {
        label: '',
        type: "selection",
        width: 50,
        align: "center",
        editHide: true,
        viewHide: true,
        selectable: (row: any, index: number) => {
          return row.isProductShelve == 2
        }
      },
      {
        label: "商品",
        prop: "productName",
        search: true,
        placeholder: "商品编号/商品名称",
        hide: true,
        editHide: true,
        viewHide: true
      },
      {
        label: "商品编号",
        prop: "id",
        align: "center",
        width: 180,
        editHide: true,
        slot: true
      },
      {
        label: '商品名称',
        prop: 'productName',
        align: "center",
        maxlength: 100,
        span: 12,
        overHidden: true,
        rules: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: 'blur'
          }
        ]
      },
      {
        label: "渠道来源",
        prop: "supplyType",
        align: "center",
        width: 150,
        type: "select",
        span: 12,
        disabled: true,
        search: true,
        dicData: [
          {
            label: "京东",
            value: 1
          },
          {
            label: "星链",
            value: 2
          },
          {
            label: '平台测试',
            value: 3
          },
          {
            label: "云商特卖",
            value: 4
          },
          {
            label: "厂商特卖",
            value: 5
          },
          {
            label: "天猫",
            value: 6
          },
          {
            label: "微唯宝特卖",
            value: 7
          },
          {
            label: "云链专供",
            value: 8
          },
          {
            label: "三方仓储",
            value: 9
          },
          {
            label: "华南一仓",
            value: 10
          },
          {
            label: "华南二仓",
            value: 11
          },
          {
            label: "华南二仓",
            value: 12
          },
          {
            label: "3C家电",
            value: 13
          },
          {
            label: "新疆专场",
            value: 14
          },
          {
            label: "西藏专场",
            value: 15
          },
          {
            label: "内蒙专场",
            value: 16
          },
          {
            label: "华东一仓",
            value: 17
          },
          {
            label: "企业专属",
            value: 18
          },
          {
            label: "云采渠道",
            value: 19
          },
          {
            label: "华东二仓",
            value: 20
          },
          {
            label: "华东三仓",
            value: 21
          }
        ]
      },
      {
        label: "商品分类",
        prop: "sortName",
        align: "center",
        width: 150,
        editHide: true
      },
      {
        label: "商品分类",
        prop: "sortId",
        hide: true,
        search: true,
        viewHide: true,
        searchSlot: true,
        editSlot: true,
        span: 12,
        rules: [
          {
            required: true,
            message: "请选择商品分类",
            trigger: 'blur'
          }
        ]
      },
      {
        label: "限购数量",
        prop: "quotaQty",
        disabled: true,
        align: "center",
        width: 100,
        span: 12,
        // @ts-ignore
        default: 0
      },
      {
        label: "成本价（元）",
        prop: "costPrice",
        align: "center",
        width: 100,
        span: 12,
        disabled: true
      },
      {
        label: "零售价（元）",
        prop: "retailPrice",
        align: "center",
        width: 100,
        span: 12,
        rules: [
          {
            required: true,
            message: '请输入零售价',
            trigger: 'blur'
          },
          {
            validator: checkPrice,
            trigger: 'blur'
          },
          {
            validator: this.checkRetailPrice,
            trigger: 'blur'
          }
        ]
      },
      {
        label: "标签",
        prop: "label",
        hide: true,
        span: 24,
        editSlot: true
      },
      {
        label: '商品主图',
        prop: 'productPic',
        align: "center",
        type: 'image',
        span: 24,
        imgSuffix: ';',
        editSlot: true,
        rules: [
          {
            required: true,
            message: "请上传商品主图",
            trigger: 'blur'
          }
        ]
      },
      {
        label: '商品详情图',
        prop: 'productDtlPic',
        hide: true,
        type: 'image',
        span: 24,
        imgSuffix: ';',
        editSlot: true
      },
      {
        label: "状态",
        prop: "status",
        search: true,
        width: 100,
        align: "center",
        type: "select",
        dicData: [
          {
            label: "上架",
            value: 1
          },
          {
            label: "下架",
            value: 2
          },
        ],
        editHide: true,
        viewHide: true
      }
    ]
  }

  // 选择的商品数据
  selectRows: IProduct[] = []

  // 选择的ids
  get ids () {
    return this.selectRows.map(item => item.id)
  }

  // 标签输入框状态
  labelInputVisible = false
  // 标签值
  labelInput = ''

  sortList: IProductSort[] = []

  dialogType: string = ''

  // 编辑/详情 规格列表
  specTableLoading = false
  specModelForm: Partial<IProductSku> = {}
  specQueryParam: any = {}
  specCrudOption: ICrudOption = {
    searchBox: false,
    menuWidth: 100,
    editTitle: "编辑规格",
    appendToBody: true,
    labelWidth: '120px',
    column: [
      {
        label: 'sku编号',
        prop: 'id',
        align: 'center',
        editHide: true
      },
      {
        label: '规格属性',
        prop: 'spData',
        align: 'center',
        slot: true,
        span: 12,
        disabled: true,
        overHidden: true,
        editSlot: true
      },
      {
        label: '成本价（元）',
        prop: 'costPrice',
        align: 'center',
        span: 12,
        disabled: true
      },
      {
        label: '零售价（元）',
        prop: 'retailPrice',
        align: 'center',
        span: 12,
        rules: [
          {
            required: true,
            message: '请输入零售价',
            trigger: 'blur'
          },
          {
            validator: checkPrice,
            trigger: 'blur'
          },
          {
            validator: this.checkSkuRetailPrice,
            trigger: 'blur'
          }
        ]
      },
      // {
      //   label: '可售库存',
      //   prop: 'stock',
      //   align: 'center',
      //   span: 12,
      //   disabled: true
      // },
      {
        label: '规格轮播图',
        prop: 'skuPic',
        align: 'center',
        type: 'image',
        imgSuffix: ';',
        span: 24,
        editSlot: true,
        rules: [
          {
            required: true,
            message: '请上传规格轮播图',
            trigger: 'blur'
          }
        ]
      },
    ]
  }
  specTableTotal = 0
  // ===============

  /**
   * 判断零售价
   * @param rule
   * @param value
   * @param callback
   */
  checkRetailPrice (rule: any, value: string, callback: Function) {
    if (parseFloat(value) > 999999.99) {
      return callback(new Error('最大不能超过999999.99'))
    }
    console.log(value)
    if (parseFloat(value) <= parseFloat(String(this.modelForm.costPrice))) {
      return callback(new Error('不能小于等于成本价'))
    }
    callback()
  }

  checkSkuRetailPrice (rule: any, value: string, callback: Function) {
    if (parseFloat(value) > 999999.99) {
      return callback(new Error('最大不能超过999999.99'))
    }
    if (parseFloat(value) <= parseFloat(String(this.specModelForm.costPrice))) {
      return callback(new Error('不能小于等于成本价'))
    }
    callback()
  }

  beforeUpload (file: File) {
    const fileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    const fileSize = file.size / 1024 < 500;

    if (!fileType) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!fileSize) {
      this.$message.error('上传图片大小不能超过 500KB!');
    }
    return fileType && fileSize;
  }

  /**
   * 获取商品分类列表
   * @param status 显示状态
   */
  getSortList (status?: number) {
    return new Promise(resolve => {
      sortPageApi({status}).then(e => {
        this.sortList = e;
        resolve(e)
      })
    })
  }

  /**
   * 打开编辑
   * @param row
   * @param index
   */
  async openEdit (row: IProduct, index: number) {
    this.dialogType = 'edit'
    await this.getSortList()
    productDetailApi(row.id).then(e => {
      const detail: IProduct = deepCopy(e)
      if (!e.label) {
        detail.labels = []
      } else {
        detail.labels = (e.label || '').split(';')
      }
      // @ts-ignore
      this.$refs.crudRef.rowEdit(detail, index)
    })
  }

  openView (row: IProduct, index: number) {
    this.dialogType = 'view'
    productDetailApi(row.id).then(e => {
      const detail: IProduct = deepCopy(e)
      if (!e.label) {
        detail.labels = []
      } else {
        detail.labels = (e.label || '').split(';')
      }
      // @ts-ignore
      this.$refs.crudRef.rowView(detail, index)
    })
  }


  openSkuEdit (row: IProductSku, index: number) {
    // @ts-ignore
    this.$refs.specCrudRefEdit.rowEdit(deepCopy(row), index)
    // productSkuDetailApi(row.id).then(e => {
    //   const detail: IProductSku = deepCopy(e)
    //   detail.spData = this.getSpDataValues(e.spData)
    //   // @ts-ignore
    //   this.$refs.specCrudRefEdit.rowEdit(detail, index)
    // })
  }

  /**
   * 取消编辑
   */
  rowCancel () {
    this.getSortList()
  }

  /**
   * 删除标签
   * @param tag 标签
   */
  labelClose (tag: string) {
    const labels = deepCopy(this.modelForm.labels)
    labels.splice(labels.indexOf(tag), 1);
    this.$set(this.modelForm, 'labels', labels)
  }

  /**
   * 标签回车
   */
  labelInputConfirm () {
    let inputValue = this.labelInput;
    if (inputValue && this.modelForm.labels) {
      if (inputValue.length > 10) {
        this.$message.error("标签最大长度不能超过10")
        return
      }
      if (!/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(inputValue)) {
        this.$message.error("标签只能数字、字母、中文")
        return
      }
      this.modelForm.labels.push(inputValue);
    }
    this.labelInputVisible = false;
    this.labelInput = '';
  }

  showLabelInput () {
    this.labelInputVisible = true;
    this.$nextTick(_ => {
      // @ts-ignore
      this.$refs.saveTagInput.$refs.input.focus();
    });
  }

  /**
   * 批量添加至货架
   * @param arr
   */
  addShelve (arr: string[]) {
    return new Promise((resolve, reject) => {
      productAddShelvesApi({ids: arr}).then(e => {
        resolve(e)
      }).catch(() => {
        reject()
      })
    })
  }

  /**
   * 同步第三方商品数据
   */
  pullProduct () {
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    productPullApi().then(e => {}).finally(() => {
      loading.close();
      this.getList()
    })
  }

  /**
   * 添加
   */
  batchAdd (arr: string[]) {
    if (!arr.length) {
      return this.$message.error("请选择商品数据")
    }
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    this.addShelve(arr).then(e => {
      this.getList()
      this.$message.success("操作成功!")
    }).finally(() => {
      loading.close()
    })
  }

  exportExcel () {
    productExportApi(this.queryParam).then(e => {
      exportFile(e, '第三方商品.xlsx')
    })
  }

  getSpDataValues (str: string) {
    let json: {key: string, value: string}[];

    try {
      json = JSON.parse(str || '[]')
    } catch (e) {
      json = []
    }

    return json.map(item => item.value).join(';')
  }

  /**
   * 编辑
   * @param form
   * @param done
   * @param loading
   */
  rowEdit (form: IProduct, done: Function, loading: Function) {
    const data: IProduct = deepCopy(form)
    if (form.labels) {
      data.label = form.labels.join(';')
    }
    delete data.labels
    if (form.productSkuVoList) {
      data.productSkuDtoList = form.productSkuVoList
    }
    productModifyApi(data).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  specRowEdit (form: IProduct, done: Function, loading: Function) {
    const index = this.modelForm.productSkuVoList?.findIndex(item => item.id === form.id)
    // @ts-ignore
    this.$set(this.modelForm.productSkuVoList, index, {...this.modelForm.productSkuVoList[index], skuPic: form.skuPic, retailPrice: form.retailPrice})
    done()
    loading()
  }

  getList () {
    this.tableLoading = true

    productNewPageApi(this.queryParam).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  created () {
    this.getSortList()
    this.getList()
  }
}
